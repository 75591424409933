import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [NgClass, TranslateModule],
  selector: 'app-loader',
  template: `
    <div
      [class.content-container-card]="useContentContainerClass"
      class="container-fluid loader-container"
    >
      @if(showMessage){
      <h3>
        {{ message || 'LOADING' | translate }}
      </h3>
      }

      <div class="spinner-border" role="status"></div>

      <!-- <div class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div> -->
    </div>
  `,
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() message?: string;
  @Input() showMessage = true;
  @Input() useContentContainerClass = true;
}
